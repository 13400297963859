<template>
  <div class="main-conent main-conent-minheight" v-loading.lock="loading">
    <el-card>
      <template #header>广告轮播图<span class="CardHeaderDesc"></span></template>
      <div class="ImgList">
        <el-card v-for="banner in banners" :key="banner.id" :body-style="{ padding: '0px'}" class="ImgBox">
          <el-image style="width:200px;height: 150px" :src="FullImgUrl(banner.img)" :preview-src-list="banners" fit="contain">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          <el-row style="padding: 14px;">
            <el-col :span="24" class="OperateCard">
              <el-input-number :controls="false" style="width:100px" placeholder="排序序号" v-model="banner.sort" @keyup.enter.native="handleUpdateImgSort(banner)" />
              <el-button class="DeleteBtn" type="danger" @click="handleDeleteImg(banner)">删 除</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>

      <el-upload class="upload-demo" drag :action="uploadUrl" multiple :on-success="handleUploadSuccess" :on-error="handleUploadError">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过5Mb</div>
      </el-upload>
    </el-card>
  </div>
</template>
<script>
import { queryActivityBanner, addActivityBanner, deleteActivityBanner, updateActivityBannerSort } from '@/service/activity.js';
import { activityFormRule } from '@/utlis/rules/activityRule.js';
import ActivityProcuctEditer from '@/components/Table/ActivityProcuctEditer.vue';
export default {
  data () {
    return {
      uploadUrl: process.env.VUE_APP_HOST + process.env.VUE_APP_APIScheme + 'upload/post/',
      activeId: 0,
      formRule: activityFormRule,
      banners: [],
      loading: false,
    }
  },
  components: { ActivityProcuctEditer },
  created () {
    this.activeId = parseInt(this.$route.query.activeId);
    this.dataBinding();
  },
  computed: {
    FullImgUrl () {
      return function (img) {
        let fullImg = process.env.VUE_APP_HOST + img;
        return fullImg;
      }
    }
  },
  methods: {
    //重置、刷新
    RefreshPage () {
      this.dataBinding();
    },
    //加载数据
    dataBinding () {
      if (this.activeId && this.activeId > 0) {
        this.loading = true;
        queryActivityBanner(this.activeId).then(res => {
          console.log(res.data);
          this.banners = res.data;
          if (!this.banners)
            this.banners = [];
          this.loading = false;
        }).catch(ex => {
          this.loading = false;
        })
      }
    },
    //删除指定图片
    handleDeleteImg (banner) {
      this.loading = true;
      const reqData = { ActivityId: this.activeId, Id: banner.id, Sort: 99, FileName: '' };
      deleteActivityBanner(reqData).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.dataBinding();
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      })
    },
    //指定图片编辑排序
    handleUpdateImgSort (banner) {
      this.loading = true;
      const reqData = { ActivityId: this.activeId, Id: banner.id, Sort: banner.sort, FileName: '' };
      console.log(reqData);
      updateActivityBannerSort(reqData).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '排序成功',
            type: 'success'
          });
          this.dataBinding();
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      })
    },
    //图片提交成功回调
    handleUploadSuccess (response, file, fileList) {
      this.loading = true;
      const reqData = { ActivityId: this.activeId, FileName: response.newFileName, Id: 0, Sort: 99 };
      addActivityBanner(reqData).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.dataBinding();
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //图片提交失败回调
    handleUploadError (response, file, fileList) {
      this.$message.error(JSON.parse(response.message).msg)
    }
  }
}
</script>
<style scoped lang="scss">
.ImgList {
  display: flex;
  flex-flow: wrap wrap;
}
.ImgBox {
  margin: 10px 10px 100px 10px;
}
.DeleteBtn {
  font-size: 10px;
}
.OperateCard {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
</style>